import Script from 'next/script'
import { useContext } from 'react'
import { GTagContext } from '../providers/GTagContext'

const Analytics = () => {
  const { dispatch } = useContext(GTagContext)
  
  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-1RPYCB47F1"
        async
        defer
        strategy="afterInteractive"
      />

      <Script
        src="/analytics/google.js"
        strategy="afterInteractive"
        type="text/javascript"
        onLoad={() => {
          dispatch({ type: 'SET_GTAG', payload: () => window.gtag })
        }}
      />
    </>
  )
}

export default Analytics
